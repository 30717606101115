@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700&family=Quicksand:wght@300;400;500;600;700&display=swap');

*,
::after,
::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:root {
    /* grey */
    --grey-50: #f8fafc;
    --grey-100: #f1f5f9;
    --grey-900: #0f172a;
    /* colors */
    --black: #1d2127;
    --nav-active: #191c21;
    --white: #fff;
    --gold: #c8974e;
    --alternative: #F2B885;
    /* #e8aa4e */
    --tan: rgb(210, 180, 140);
    /* --secondary: #fdd3b9 #fcad7c; */
    --gradient: linear-gradient(to bottom, rgb(252, 173, 124), #fdb78b, #fdc09a, #fdcaa9, #fdd3b9);
    --red-light: #f8d7da;
    --red-dark: rgb(132, 32, 41);
    --green-light: #d1e7dd;
    --green-dark: rgb(21, 117, 72);

    /* fonts  */
    --headingFont: 'Quicksand', sans-serif;
    --bodyFont: 'Nunito', sans-serif;
    --small-text: 0.875rem;
    --extra-small-text: 0.7em;
    /* rest of the vars */
    --backgroundColor: var(--grey-50);
    --heroBackgroundColor: #F2EFEB;
    --textColor: var(--black);
    --borderRadius: 0.25rem;
    --letterSpacing: 1px;
    --transition: 0.3s ease-in-out all;
    --max-width: 1200px;
    --fixed-width: 600px;
    --radius: .5rem;
    /* box shadow*/
    --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
    --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
        0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset var(--shadow-1);
    background-color: transparent;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: var(--white);
}

::-webkit-scrollbar-thumb {
    background-color: var(--black);
}

body {
    font-family: var(--bodyFont);
    font-weight: 400;
    line-height: 1.75;
    color: var(--textColor);
    min-height: 100vh;
}

body.black {
    --white: #000;
    --black: #fff;
    color: var(--black);
    background: var(--white);
}

h1,
h2,
h3,
h4,
h5 {
    margin: 3rem 0 1.38rem;
    margin-top: 0;
    font-family: serif;
    font-weight: 400;
    line-height: 1.3;
    text-transform: capitalize;
}

h1 {
    margin-top: 0;
    font-size: 3.052rem;
    font-weight: 500;
}

h2 {
    font-size: 2.441rem;
}

h3 {
    font-size: 1.953rem;
}

h4 {
    font-size: 1.563rem;
}

h5 {
    font-size: 1.25rem;
}

small,
.text_small {
    font-size: 0.8rem;
}

p {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    max-width: 60ch;
}

a {
    text-decoration: none;
    color: inherit;
}

ul {
    list-style-type: none;
    padding: 0;
}

img,
picture,
svg {
    max-width: 100%;
    display: block;
    object-fit: cover;
}

input,
button,
textarea,
select {
    font: inherit;
    border: none;
    outline: none;
    color: inherit;
    background-color: inherit;
}

button {
    cursor: pointer;
}

.container {
    margin: 0 auto;
    width: 90%;
}

.card {
    background-color: var(--white);
    border-radius: var(--borderRadius);
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.font-weight-semibold {
    font-weight: 600 !important;
}

/* form */

.form {
    width: 100%;
    max-width: var(--fixed-width);
    margin: 0 auto;
}

.form-label {
    display: block;
    font-size: var(--small-text);
    margin-bottom: 0.5rem;
    text-transform: capitalize;
    letter-spacing: var(--letterSpacing);
    color: var(--black);
    font-weight: 600;
}

.form-input,
.form-textarea,
.form-select {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid #ced4da;
    transition: border-color .15s ease-in-out;
}


.form-input:focus,
.form-textarea:focus {
    color: #495057;
    background-color: #fff;
    border-color: var(--black);
    outline: 0;
}

.container-input {
    margin-bottom: 1rem;
}

.form-textarea {
    height: 7rem;
}

::placeholder {
    font-family: inherit;
    color: #9fa6ad;
}

.form-alert {
    color: var(--red-dark);
    letter-spacing: var(--letterSpacing);
    text-transform: capitalize;
    font-size: var(--extra-small-text);
}

.file-label {
    color: var(--white);
    background: var(--black);
    display: inline-block;
    padding: .5rem 1rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .2rem;
    margin: 1rem 0;
    cursor: pointer;
}

.file-label+span {
    display: block;
    width: 100%;
    word-break: break-all;
}

.file-label .file-btn {
    display: block;

}

.container-input input[type=file] {
    display: none;
}

/* button */

.btn {
    cursor: pointer;
    color: var(--white);
    border-radius: var(--borderRadius);
    letter-spacing: var(--letterSpacing);
    padding: 0.375rem 0.75rem;
    box-shadow: var(--shadow-1);
    transition: var(--transition);
    text-transform: capitalize;
    display: inline-block;
    background: var(--gold);
}

.draggable {
    cursor: grabbing;
}

/* .btn:hover {
    background-color: var(--black);
    box-shadow: var(--shadow-3);
} */

.dark-btn {
    z-index: 1;
    overflow: hidden;
    position: relative;
    line-height: 1;
    padding: 1.8em 3.25em 1.8em;
    border-radius: 0px;
    font-size: .7rem;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: color 1s ease;
    border: 2px solid var(--black);
    background-color: var(--black);
    color: var(--white);
}

.dark-btn:hover {
    color: var(--black);
}

.dark-btn::after {
    z-index: -1;
    content: '';
    background-color: var(--white);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 150%;
    height: 100%;
    transform: rotate3d(0, 0, 1, 10deg) translate3d(-1.2em, 110%, 0);
    transform-origin: 0% 100%;
    transition: transform 0.5s cubic-bezier(0.59, 0.03, 0.2, 1), -webkit-transform 0.5s cubic-bezier(0.59, 0.03, 0.2, 1);
}

.dark-btn:hover::after {
    transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0);
}

.login {
    --black: #000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    background: var(--white);
}

.black .login {
    --black: #fff;
}

.sidebar.sidebar-open {
    z-index: 102 !important;
    opacity: 1;
}

.sidebar.sidebar-open .wrapper-sidebar {
    transform: translatex(0)
}